import * as React from "react";

import { AccommodationKindFacetWidget, DynamicAccommodationKindFacet, warmupCache } from "./AccommodationKindFacet";
import { FacetDisplayType, FacetOptions, getFacetDisplayTypeOptions } from "../../../utils/facet.util";
import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import {
    LocalizedLabelOptions,
    LocalizedTitleOptions,
    getWidgetLabelOptions,
    getWidgetTitleOptions,
    labelStylingOptions,
    titleStylingOptions,
} from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { ModalLabel } from "../subject/Subject.types";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import { getModalLabelOptions } from "../../../components/utils";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends FacetOptions, LocalizedTitleOptions, LocalizedLabelOptions {
    addIcon: boolean;
    arrowColor: string;
    displayBtn: boolean;
    greyOutUnavailableAccoKinds: boolean;
    highlightColor: string;
    highlightInput: boolean;
    iconColor: string;
    iconOutside: boolean;
    iconRight: boolean;
    showApplyClear: boolean;
    showArrow: boolean;
    styleIds?: string[];
    useAsSingularFilter?: boolean;
    displayShowAll?: boolean;
    textTitleColor?: string;
    textLabelColor?: string;
    noOfAccommodationByDefault: number;
    toggleAccommodation: boolean;
    localizedContent: LocaleContent[];
    closeIcon?: boolean;
    enableModalLabel?: boolean;
    localizedModalLabel?: ModalLabel[];
}

export interface LocaleContent {
    moreAccommodationButtonText: string;
    lessAccommodationButtonText: string;
    locale: string;
}

const TARGETS = ["accommodation-kinds"];
const isModalPopup = (displayType: FacetDisplayType): boolean => {
    const modalTypes = [FacetDisplayType.POPUP, FacetDisplayType.MODAL_POPUP];
    return modalTypes.includes(displayType);
};
export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-accokind-widget",
    name: getI18nLocaleObject(namespaceList.dynamicAccoKind, "accoKind"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicAccoKind, "accoKinds"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetAgenda, "general"),
                    properties: [
                        [
                            ...getFacetDisplayTypeOptions(),
                            {
                                variable: "greyOutUnavailableAccoKinds",
                                label: getI18nLocaleObject(namespaceList.dynamicAccoKind, "greyOutUnavailableAccoKinds"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP || options.displayType === FacetDisplayType.GRID,
                            },
                            {
                                variable: "useAsSingularFilter",
                                label: getI18nLocaleObject(namespaceList.dynamicAccoKind, "useAsSingularFilter"),
                                type: "checkbox",
                            },
                            {
                                variable: "displayShowAll",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayShowAll"),
                                type: "checkbox",
                            },
                            {
                                variable: "noOfAccommodationByDefault",
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.dynamicAccoKind, "noOfAccommodationByDefault"),
                            },
                            {
                                variable: "toggleAccommodation",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "toggleAmenities"),
                                visible: (options: WidgetOptions) => !!+options.noOfAccommodationByDefault!,
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "moreAccommodationButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "moreAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "showDefaultTextIfEmpty"),
                                        visible: (item: any, locale: any, options: WidgetOptions) => options.toggleAccommodation,
                                    },
                                    {
                                        variable: "lessAccommodationButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "lessAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "showDefaultTextIfEmpty"),
                                        visible: (item: any, locale: any, options: WidgetOptions) => options.toggleAccommodation,
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.toggleAccommodation && !!+options.noOfAccommodationByDefault!,
                            }),
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...getWidgetLabelOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetAgenda, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightInput",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightInput"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "highlightColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightColor"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                visible: (options: WidgetOptions) =>
                                    (options.displayType === FacetDisplayType.POPUP && options.highlightInput) || (options.displayType === FacetDisplayType.MODAL_POPUP && options.highlightInput),
                            },
                            {
                                variable: "displayBtn",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBtn"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "showApplyClear",
                                label: getI18nLocaleObject(namespaceList.admin, "showApplyClear"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closeIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            ...getModalLabelOptions(),
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                            ...labelStylingOptions<WidgetOptions>({ LabelFontColorVariable: "textLabelColor" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const accokindWidget: PageWidgetSpec<WidgetOptions> = {
    id: "AccokindWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicAccoKind, "accoKind"),
    description: getI18nLocaleObject(namespaceList.dynamicAccoKind, "accoKindDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        addIcon: false,
        arrowColor: "default",
        displayBtn: false,
        displayType: FacetDisplayType.POPUP,
        greyOutUnavailableAccoKinds: false,
        highlightColor: "theme-ctaColor",
        highlightInput: false,
        iconColor: "default",
        iconOutside: false,
        iconRight: false,
        numberOfColumnsInGridView: 1,
        showApplyClear: false,
        showArrow: false,
        styleIds: [],
        toggleAccommodation: false,
        noOfAccommodationByDefault: 4,
        localizedContent: [],
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        if (isServerSide()) {
            await warmupCache({
                dynamicContainerOptions,
                options: widget.options,
                context,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                availabilityIsFetching: true,
                availableAccoKindIds: [],
                dispatchAction: context.reduxStore.store.dispatch,
                availabilityState: context.reduxStore.store.getState().availabilityState,
            });
        }
        return <DynamicAccommodationKindFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} />;
    },
    async initDefaultFilter(widget, context, dispatcher): Promise<void> {
        await AccommodationKindFacetWidget.initDefaultFilter({
            options: widget.options,
            context,
            dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
            dispatchAction: dispatcher,
        } as any);
    },
};
