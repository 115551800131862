import { SomeInputSpec } from "../form-specs";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export enum FacetDisplayType {
    GRID = "displayGrid",
    LIST = "displayList",
    POPUP = "displayPopup",
    MODAL_POPUP = "displayModalPopup",
}

export interface FacetOptions {
    displayType: FacetDisplayType;
    numberOfColumnsInGridView: number;
}

export const getFacetDisplayTypeOptions = (): Array<SomeInputSpec<any, keyof FacetOptions>> => [
    {
        variable: "displayType",
        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayType"),
        type: "select",
        default: FacetDisplayType.POPUP,
        optionList: [
            {
                value: FacetDisplayType.GRID,
                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayGrid"), // TODO: Add translation
            },
            {
                value: FacetDisplayType.LIST,
                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayList"),
            },
            {
                value: FacetDisplayType.POPUP,
                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayPopup"),
            },
            {
                value: FacetDisplayType.MODAL_POPUP,
                label: getI18nLocaleObject(namespaceList.subject, "displayModalPopup"),
            },
        ],
    },
    {
        variable: "numberOfColumnsInGridView",
        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "numberOfColumnsInGridView"),
        type: "select",
        optionList: [
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
        ],
        default: 1,
        visible: (options: FacetOptions) => options.displayType === FacetDisplayType.GRID,
    },
];
