import { ActionType } from "../../../redux/actions";
import { Aggregation } from "@maxxton/cms-mxts-api";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";

export const ACCOMMODATIONKIND_FACET_AGGREGATION: Aggregation = {
    name: "ACCOMMODATIONKIND_FACET",
    field: "ACCOMMODATIONKIND_ID",
    excludeFields: ["ACCOMMODATIONKIND_ID"],
    type: "FACET",
    size: 1000,
};

export const getAction = (filter: dynamicFilterType, payload: DynamicFilter) => {
    const action: FilterChangeAction = {
        type: ActionType.FilterChange,
        filter,
        payload,
    };
    return action;
};

export const mergeAggregationsWithDynamicFilter = (dynamicFilter: DynamicFilter, aggregations: Aggregation[]) => {
    const extraAggregations = [...(dynamicFilter?.extraAggregations || []), ...aggregations];
    return { ...dynamicFilter, extraAggregations };
};
