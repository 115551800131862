import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { ACCOMMODATIONKIND_FACET_AGGREGATION, getAction, mergeAggregationsWithDynamicFilter } from "./accommodationKindFacet.util";
import { AccoKind, AvailabilityResult } from "@maxxton/cms-mxts-api";
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LocaleContent, WidgetOptions } from "./";
import LocalizedTitleAndLabel, { LocalizedTitleProps } from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { Site, WithId } from "@maxxton/cms-api";
import { dispatchEmptyAction, getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { BoxModelUtil } from "../../../utils/boxmodel.util";
import { CurrentLocale } from "../../../app.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FacetDisplayType } from "../../../utils/facet.util";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLabelOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { icons } from "../../../utils/generic.util";
import { isEmpty } from "lodash";
import namespaceList from "../../../i18n/namespaceList";

interface AccommodationKindFacetProps extends DynamicWidgetBaseProps<WidgetOptions>, AccommodationKindFacetStoreProps, AccommodationKindFacetDispatchProps {}

interface AccommodationKindFacetState {
    accoKindsAreCleared: boolean;
    accokindDropdownOpen?: boolean;
    allBookableAccoKinds: AccoKind[];
    availableAccoKinds: AccoKind[];
    disableWidget: boolean;
    removedAccoKindIds: number[];
    selectedAccoKindIds: number[];
    selectedAccoKindNames: Array<string | undefined>;
    accommodationModalPopup: boolean;
    expanded: boolean;
}

interface AccommodationKindFacetStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityIsFetching: boolean;
    availableAccoKindIds: number[];
    availabilityState: AvailabilityState;
}

interface AccommodationKindFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction>;
}

export class AccommodationKindFacetWidget extends React.Component<AccommodationKindFacetProps, AccommodationKindFacetState> {
    constructor(props: AccommodationKindFacetProps) {
        super(props);
        this.state = {
            accoKindsAreCleared: false,
            accokindDropdownOpen: false,
            allBookableAccoKinds: [],
            availableAccoKinds: [],
            disableWidget: true,
            removedAccoKindIds: [],
            selectedAccoKindIds: [],
            selectedAccoKindNames: [],
            accommodationModalPopup: false,
            expanded: false,
        };
    }

    public async componentDidMount() {
        const { dispatchAction } = this.props;
        dispatchEmptyAction(dispatchAction);
        const allBookableAccoKinds = await getAllBookableAccoKinds(this.props);
        this.setState({ allBookableAccoKinds, disableWidget: !isClientLoggedIn() });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<AccommodationKindFacetProps>): void {
        const {
            availabilityState: { availabilityResult },
        } = this.props;
        const currentAccommodationKinds = availabilityResult?.response?.accommodationkinds;
        const nextAvailabilityResponse = nextProps?.availabilityState?.availabilityResult?.response;
        if (!isEqual(currentAccommodationKinds, nextAvailabilityResponse?.accommodationkinds)) {
            const accoKindIds = nextAvailabilityResponse?.accommodationkinds;
            getAllBookableAccoKinds(nextProps, accoKindIds).then((response) => {
                this.setState({ allBookableAccoKinds: response });
            });
        }
    }

    public componentDidUpdate(prevProps: AccommodationKindFacetProps, prevState: AccommodationKindFacetState) {
        const { availabilityIsFetching, availableAccoKindIds, dynamicFilter } = this.props;

        const allBookableAccoKindsChanged = !isEqual(prevState.allBookableAccoKinds, this.state.allBookableAccoKinds);
        const availableAccoKindIdsChanged = !availabilityIsFetching && !isEqual(prevProps.availableAccoKindIds, availableAccoKindIds);
        const selectedAccoKindsChanged = dynamicFilter.accokindids && !isEqual(prevState.selectedAccoKindIds, dynamicFilter.accokindids);
        const clearAccoKinds = !this.state.accoKindsAreCleared && !!this.state.selectedAccoKindIds && !dynamicFilter.accokindids;

        if (allBookableAccoKindsChanged || availableAccoKindIdsChanged) {
            this.updateAccoKinds(this.props);
        }

        if (allBookableAccoKindsChanged || selectedAccoKindsChanged || clearAccoKinds) {
            this.updateSelectedAccoKinds(this.props, clearAccoKinds);
        }
    }
    /* jscpd:ignore-start */
    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        if (options.displayType === FacetDisplayType.GRID) {
            return this.renderGrid(hideWidget, titleOptions);
        }
        if (options.displayType === FacetDisplayType.LIST) {
            return this.renderList(hideWidget, titleOptions);
        }
        if (options.displayType === FacetDisplayType.MODAL_POPUP) {
            return this.renderModalPopup(hideWidget, titleOptions);
        }
        return this.renderPopup(hideWidget, titleOptions);
    }
    /* jscpd:ignore-end */
    private renderGrid(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { availableAccoKinds, selectedAccoKindIds, allBookableAccoKinds, expanded } = this.state;
        const { options, availableAccoKindIds, availabilityIsFetching, context } = this.props;
        const { currentLocale, site } = context;
        let usedAccoKinds = availableAccoKinds;
        const currentLocalized = getLocalizedContent({ currentLocale, site, localizedContent: options.localizedContent });
        if (options.greyOutUnavailableAccoKinds) {
            usedAccoKinds = allBookableAccoKinds;
        }
        if (availabilityIsFetching) {
            return <Loader type="acco-kind-facet-grid" />;
        }
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className={classNames("acco-kind-facet-grid-layout", "grid-layout", `grid-layout--${options.numberOfColumnsInGridView}`, `${hideWidget}`)}>
                    {usedAccoKinds.slice(0, options.noOfAccommodationByDefault && !expanded ? options.noOfAccommodationByDefault : usedAccoKinds.length).map((accoKind, index) => (
                        <div
                            key={index}
                            className={classNames("acco-kind-facet-grid-layout__acco-kind", {
                                "acco-kind-facet-grid-layout__acco-kind--selected": selectedAccoKindIds.includes(accoKind.accommodationkindId),
                                "acco-kind-facet-grid-layout__acco-kind--unavailable disabled": !availableAccoKinds.includes(accoKind) || !availableAccoKindIds.includes(accoKind.accommodationkindId),
                                "acco-kind-facet-grid-layout__acco-kind--loading": availabilityIsFetching,
                            })}
                            onClick={() => this.handleGridAccoKindClick(accoKind.accommodationkindId)}
                        >
                            <span className={classNames("acco-kind__name", "acco-kind__icon", `acco-kind__icon--${accoKind.name.toLowerCase()}`)}>{accoKind.name}</span>
                        </div>
                    ))}
                    {this.showMoreFiltersContent({ accoResult: usedAccoKinds, options, currentLocalized, expanded, currentLocale, site })}
                </div>
            </React.Fragment>
        );
    }

    private renderList(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { availableAccoKinds, selectedAccoKindIds, expanded } = this.state;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const currentLocalized = getLocalizedContent({ currentLocale, site, localizedContent: options.localizedContent });
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className={`dynamic-accokinds-facet ${hideWidget}`}>
                    {availableAccoKinds.slice(0, options.noOfAccommodationByDefault && !expanded ? options.noOfAccommodationByDefault : availableAccoKinds.length).map((accoKind, count) => (
                        <div key={count} className="search-window__item">
                            <input
                                id={`accokinds-${count}`}
                                onChange={this.handleAccoKind.bind(this, accoKind)}
                                type="checkbox"
                                name={accoKind.name}
                                checked={selectedAccoKindIds.indexOf(accoKind.accommodationkindId) > -1}
                                className="search-window__item-input"
                            />
                            <label className="accokind-name search-window__item-label" htmlFor={`accokinds-${count}`} onClick={this.handleAccoKind.bind(this, accoKind)}>
                                {accoKind.name}
                            </label>
                        </div>
                    ))}
                    {this.showMoreFiltersContent({ accoResult: availableAccoKinds, options, currentLocalized, expanded, currentLocale, site })}
                </div>
            </React.Fragment>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    private renderPopup(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { allBookableAccoKinds, availableAccoKinds, selectedAccoKindIds, selectedAccoKindNames, expanded } = this.state;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const { highlightColor, highlightInput, useAsSingularFilter, closeIcon, enableModalLabel, localizedModalLabel } = options;
        const labels: string[] = [];
        if (selectedAccoKindIds && selectedAccoKindIds.length > 0) {
            availableAccoKinds.forEach((accoKind: AccoKind) => {
                selectedAccoKindIds.forEach((id) => (id === accoKind.accommodationkindId ? labels.push(accoKind.name) : ""));
            });
        }
        const labelOptions = getLabelOptions(options, site, currentLocale);
        // const label = labels.length > 0 ? labels.join(", ") : "";
        let usedAccoKinds: AccoKind[];
        const currentLocalized = getLocalizedContent({ currentLocale, site, localizedContent: options.localizedContent });
        const modalLabel = enableModalLabel && localizedModalLabel && getLocalizedContent({ site, currentLocale, localizedContent: localizedModalLabel })?.labelText;

        if (options.greyOutUnavailableAccoKinds) {
            usedAccoKinds = allBookableAccoKinds;
        } else {
            usedAccoKinds = availableAccoKinds;
        }
        /* jscpd:ignore-start */
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div
                    // eslint-disable-next-line max-len
                    className={`dynamic-acco-kind search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                        options.iconColor && options.iconColor.indexOf("theme") > -1
                            ? `color-${options.iconColor}`
                            : options.iconColor && options.iconColor.indexOf("rgba") === -1
                            ? "icon-" + options.iconColor
                            : ""
                    } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                        options.showArrow ? "show-arrows" : ""
                    } ${hideWidget}`}
                    style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                >
                    <Dropdown
                        nav
                        className={`accokind-inner search-filter-box--inner ${
                            isEmpty(selectedAccoKindIds) && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""
                        }`}
                        isOpen={this.state.accokindDropdownOpen}
                        toggle={this.toggleAccokind}
                        {...{ style: isEmpty(selectedAccoKindIds) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {} }}
                    >
                        <DropdownToggle
                            nav
                            {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                            className={`search-filter-box--inner__label ${selectedAccoKindIds.length > 0 ? "active" : ""}`}
                        >
                            <span className="filter-data-wrap">
                                <LocalizedTitleAndLabel {...labelOptions} />
                                <span className="search-filter-box--inner__label-selected">
                                    {selectedAccoKindNames.length > 0
                                        ? `${selectedAccoKindNames[0]}${selectedAccoKindNames.length > 1 ? icons.PLUS : ""}${selectedAccoKindNames.length - 1 || ""}`
                                        : getI18nLocaleString(namespaceList.dynamicAccoKind, "allAccoKinds", currentLocale, site)}
                                </span>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="accokinds-dropdown search-filter-box--inner__dropdown">
                            {modalLabel && <label className="label-inside-popup">{modalLabel}</label>}
                            {closeIcon && (
                                <span className="popup-close" onClick={this.toggleAccokind}>
                                    <FontAwesome name="close" />
                                </span>
                            )}

                            <div className="dropdown-inner">
                                {options.displayShowAll && (
                                    <div className="search-window__item" onClick={this.clearResults}>
                                        <input id="accokinds-list" type="checkbox" name="clear-all" className="search-window__item-input" />
                                        <label className="accokind-name search-window__item-label" htmlFor="accokinds-clearall">
                                            {getI18nLocaleString(namespaceList.dynamicAccoKind, "allAccoKinds", currentLocale, site)}
                                        </label>
                                    </div>
                                )}
                                {usedAccoKinds.slice(0, options.noOfAccommodationByDefault && !expanded ? options.noOfAccommodationByDefault : usedAccoKinds.length).map((accoKind, count) => {
                                    const isSelected = !(selectedAccoKindIds.length === 1 && useAsSingularFilter && accoKind.accommodationkindId !== selectedAccoKindIds[0]);
                                    return options.greyOutUnavailableAccoKinds || isSelected ? (
                                        <div
                                            key={count}
                                            className={classNames("search-window__item", {
                                                "label-with-checkbox__greyed-out":
                                                    (options.greyOutUnavailableAccoKinds &&
                                                        availableAccoKinds.findIndex((someAccoKind) => someAccoKind.accommodationkindId === accoKind.accommodationkindId) === -1) ||
                                                    !isSelected,
                                            })}
                                        >
                                            <input
                                                id={`accokinds-${count}`}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAccoKind(accoKind, e)}
                                                type="checkbox"
                                                name={accoKind.name}
                                                checked={selectedAccoKindIds.indexOf(accoKind.accommodationkindId) > -1}
                                                disabled={
                                                    (selectedAccoKindIds.indexOf(accoKind.accommodationkindId) === -1 &&
                                                        availableAccoKinds.findIndex((someAccoKind) => someAccoKind.accommodationkindId === accoKind.accommodationkindId) === -1) ||
                                                    !isSelected
                                                }
                                                className="search-window__item-input"
                                            />
                                            <label className="accokind-name search-window__item-label" htmlFor={`accokinds-${count}`}>
                                                {accoKind.name}
                                            </label>
                                        </div>
                                    ) : null;
                                })}
                                {this.showMoreFiltersContent({ accoResult: usedAccoKinds, options, currentLocalized, expanded, currentLocale, site })}
                            </div>
                            {options.showApplyClear ? (
                                <div className="filter-action-buttons">
                                    <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                        {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                    </a>
                                    <a className="action-button__apply" role="button" onClick={this.applyResults}>
                                        {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                                    </a>
                                </div>
                            ) : null}
                        </DropdownMenu>
                        <div className={`${this.state.accokindDropdownOpen ? "backdrop-popup" : ""}`} onClick={this.state.accokindDropdownOpen ? this.toggleAccokind : undefined}></div>
                    </Dropdown>
                </div>
            </React.Fragment>
        );
    }

    private renderModalPopup(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { availableAccoKinds, selectedAccoKindIds, selectedAccoKindNames } = this.state;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const { highlightColor, highlightInput } = options;
        const labelOptions = getLabelOptions(options, site, currentLocale);
        const labels: string[] = [];
        if (selectedAccoKindIds?.length) {
            availableAccoKinds.forEach((accoKind: AccoKind) => {
                selectedAccoKindIds.forEach((id) => (id === accoKind.accommodationkindId ? labels.push(accoKind.name) : ""));
            });
        }

        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div
                    className={classNames(
                        `dynamic-acco-kind search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                            options.iconColor?.includes("theme") ? `color-${options.iconColor}` : options.iconColor && options.iconColor.indexOf("rgba") === -1 ? "icon-" + options.iconColor : ""
                        } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                            options.showArrow ? "show-arrows" : ""
                        } ${hideWidget}`
                    )}
                    style={{ color: options.addIcon && options?.iconColor?.includes("rgba") ? options.iconColor : undefined }}
                >
                    <div
                        className={`accokind-inner ${isEmpty(selectedAccoKindIds) && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""}`}
                        {...{ style: isEmpty(selectedAccoKindIds) && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {} }}
                        onClick={this.handleAccommodationInfoModal}
                    >
                        <a className="search-filter-box--inner__label accokind-label">
                            <span className="filter-data-wrap">
                                <LocalizedTitleAndLabel {...labelOptions} />
                                <span className="search-filter-box--inner__label-selected">
                                    {selectedAccoKindNames.length > 0
                                        ? `${selectedAccoKindNames[0]}${selectedAccoKindNames.length > 1 ? icons.PLUS : ""}${selectedAccoKindNames.length - 1 || ""}`
                                        : getI18nLocaleString(namespaceList.dynamicAccoKind, "allAccoKinds", currentLocale, site)}
                                </span>
                            </span>
                            {this.accommodationModal()}
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private showMoreFiltersContent = (showMoreFilterParams: {
        accoResult: AccoKind[];
        options: WidgetOptions;
        currentLocalized: LocaleContent | null;
        expanded: boolean;
        currentLocale: CurrentLocale;
        site: Site & WithId;
    }) => {
        const { accoResult, options, currentLocalized, expanded, currentLocale, site } = showMoreFilterParams;
        return (
            <>
                {accoResult && options.noOfAccommodationByDefault && accoResult.length > options.noOfAccommodationByDefault && !expanded ? (
                    <a className="show-more-filters" onClick={() => this.handleShowMoreLocations()}>
                        {currentLocalized && options.toggleAccommodation && <span>{currentLocalized.moreAccommodationButtonText}</span>}
                        {(!currentLocalized || !(options.toggleAccommodation && currentLocalized.moreAccommodationButtonText)) && <FontAwesome name="plus" />}
                        {(!currentLocalized || !(options.toggleAccommodation && currentLocalized.moreAccommodationButtonText)) &&
                            getI18nLocaleString(namespaceList.dynamicAccoKind, "allAccoKinds", currentLocale, site)}
                    </a>
                ) : (
                    accoResult &&
                    expanded &&
                    options.toggleAccommodation &&
                    currentLocalized &&
                    currentLocalized.lessAccommodationButtonText && (
                        <a className="show-more-filters" onClick={() => this.handleShowMoreLocations()}>
                            {currentLocalized && options.toggleAccommodation && <span>{currentLocalized.lessAccommodationButtonText}</span>}
                            {(!currentLocalized || !(options.toggleAccommodation && currentLocalized.lessAccommodationButtonText)) && <FontAwesome name="minus" />}
                            {(!currentLocalized || !(options.toggleAccommodation && currentLocalized.lessAccommodationButtonText)) &&
                                getI18nLocaleString(namespaceList.dynamicAccoKind, "allAccoKinds", currentLocale, site)}
                        </a>
                    )
                )}
            </>
        );
    };

    private applyResults = () => {
        const { selectedAccoKindIds, removedAccoKindIds } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.addaccokind,
            payload: { accokindids: selectedAccoKindIds, removedAccoKindIds },
        };
        this.props.dispatchAction(action);
        this.setState({ removedAccoKindIds: [] }, () => {
            this.toggleAccokind();
        });
    };

    private clearResults = () => {
        const { selectedAccoKindIds } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.removeaccokind,
            payload: { accokindids: selectedAccoKindIds },
        };
        this.props.dispatchAction(action);
        this.setState({ removedAccoKindIds: [] }, () => {
            this.toggleAccokind();
        });
    };

    private toggleAccokind = () => {
        this.setState((state) => ({
            ...state,
            accokindDropdownOpen: !state.accokindDropdownOpen,
        }));
    };

    private handleShowMoreLocations = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    private updateAccoKinds = (props: AccommodationKindFacetProps): void => {
        const { allBookableAccoKinds } = this.state;
        const availableAccoKinds = allBookableAccoKinds.filter((accoKind: AccoKind) => props.availableAccoKindIds.indexOf(accoKind.accommodationkindId) !== -1);

        this.setState({ availableAccoKinds });
    };

    private updateSelectedAccoKinds = (props: AccommodationKindFacetProps, clearAccoKinds = false) => {
        const {
            dynamicFilter: { accokindids: accoKindIds },
        } = props;

        const { allBookableAccoKinds } = this.state;

        if (clearAccoKinds) {
            this.setState({
                accoKindsAreCleared: true,
                selectedAccoKindIds: [],
                selectedAccoKindNames: [],
            });
        } else {
            const selectedAccoKindNames = accoKindIds
                ? accoKindIds
                      .map((id) => {
                          const foundAccoKind = allBookableAccoKinds.find((accoKind) => accoKind.accommodationkindId === id);
                          if (foundAccoKind) {
                              return foundAccoKind.name;
                          }
                      })
                      .filter((res) => res !== undefined)
                : this.state.selectedAccoKindNames;
            this.setState({
                accoKindsAreCleared: false,
                selectedAccoKindNames,
                selectedAccoKindIds: accoKindIds || this.state.selectedAccoKindIds,
            });
        }
    };

    private handleAccoKind = (accoKind: AccoKind, event: React.ChangeEvent<HTMLInputElement>) => {
        const { options } = this.props;
        const { removedAccoKindIds } = this.state;
        const selectedAccoKindIds = event.target.checked
            ? this.state.selectedAccoKindIds.concat(accoKind.accommodationkindId)
            : this.state.selectedAccoKindIds.filter((accokindid) => accokindid !== accoKind.accommodationkindId);
        const selectedAccoKindNames = event.target.checked ? this.state.selectedAccoKindNames.concat(accoKind.name) : this.state.selectedAccoKindNames.filter((name) => name !== accoKind.name);
        if (!event.target.checked) {
            removedAccoKindIds.push(accoKind.accommodationkindId);
        }
        this.setState({ selectedAccoKindIds, selectedAccoKindNames, removedAccoKindIds });
        if (!options.showApplyClear) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: event.target.checked ? dynamicFilterType.addaccokind : dynamicFilterType.removeaccokind,
                payload: {
                    accokindids: event.target.checked ? selectedAccoKindIds : [accoKind.accommodationkindId],
                },
            };
            this.props.dispatchAction(action);
        }
    };

    private handleGridAccoKindClick = (accoKindId: number): void => {
        // If the resort is currently selected in the state, it should be removed
        // If the resort is not yet selected, it should be added
        const addAccoKind = !this.state.selectedAccoKindIds.includes(accoKindId);

        const updatedSelectedResortIds = addAccoKind ? this.state.selectedAccoKindIds.concat(accoKindId) : this.state.selectedAccoKindIds.filter((selectedId) => selectedId !== accoKindId);

        this.setState({
            selectedAccoKindIds: updatedSelectedResortIds,
            removedAccoKindIds: !addAccoKind ? this.state.removedAccoKindIds.concat(accoKindId) : this.state.removedAccoKindIds,
        });

        if (!this.props.options.showApplyClear) {
            this.dispatchAccoKindChange(accoKindId, addAccoKind, updatedSelectedResortIds);
        }
    };

    public static async initDefaultFilter(props: AccommodationKindFacetProps): Promise<void> {
        await AccommodationKindFacetWidget.populateDynamicFilterWithPreSelectedFilters(props);
    }

    private static async populateDynamicFilterWithPreSelectedFilters(props: AccommodationKindFacetProps): Promise<DynamicFilter> {
        const { dynamicFilter, dispatchAction } = props;
        const filterType = dynamicFilterType.addPreselectedFilters;
        const updatedDynamicFilter = mergeAggregationsWithDynamicFilter(dynamicFilter, [ACCOMMODATIONKIND_FACET_AGGREGATION]);
        const action = getAction(filterType, updatedDynamicFilter);
        dispatchAction(action);
        return updatedDynamicFilter;
    }

    private dispatchAccoKindChange = (accoKindId: number, addAccoKind: boolean, selectedAccoKindIds: number[]): void => {
        if (addAccoKind) {
            this.props.dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.addaccokind,
                payload: { accokindids: selectedAccoKindIds },
            });
        } else {
            this.props.dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.removeaccokind,
                payload: { accokindids: [accoKindId] },
            });
        }
    };

    private handleAccommodationInfoModal = () => {
        this.setState({ accommodationModalPopup: !this.state.accommodationModalPopup });
    };

    private accommodationModal = () => {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const { allBookableAccoKinds, availableAccoKinds, selectedAccoKindIds, accommodationModalPopup } = this.state;
        const { useAsSingularFilter } = options;
        const labels: string[] = [];

        if (selectedAccoKindIds?.length) {
            availableAccoKinds.forEach((accoKind: AccoKind) => {
                selectedAccoKindIds.forEach((id) => (id === accoKind.accommodationkindId ? labels.push(accoKind.name) : ""));
            });
        }
        const usedAccoKinds: AccoKind[] = options.greyOutUnavailableAccoKinds ? allBookableAccoKinds : availableAccoKinds;
        return (
            <Modal isOpen={accommodationModalPopup} toggle={this.handleAccommodationInfoModal} className={"resort-on-modal modal-lg"}>
                <ModalHeader tag="h4" toggle={this.handleAccommodationInfoModal} className="no-background">
                    {getI18nLocaleString(namespaceList.widgetTypeSearch, "accoKind", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="search-filter-box">
                        <div className="accokind-inner">
                            {usedAccoKinds.map((accoKind, count) => {
                                const isSelected = !(selectedAccoKindIds.length === 1 && useAsSingularFilter && accoKind.accommodationkindId !== selectedAccoKindIds[0]);
                                const selectedAccoKind = selectedAccoKindIds.includes(accoKind.accommodationkindId);
                                return options.greyOutUnavailableAccoKinds || isSelected ? (
                                    <div
                                        key={count}
                                        className={classNames("search-window__item", {
                                            "label-with-checkbox__greyed-out":
                                                (options.greyOutUnavailableAccoKinds &&
                                                    availableAccoKinds.findIndex((someAccoKind) => someAccoKind.accommodationkindId === accoKind.accommodationkindId) === -1) ||
                                                !isSelected,
                                        })}
                                    >
                                        <input
                                            id={`accokinds-${count}`}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAccoKind(accoKind, e)}
                                            type="checkbox"
                                            name={accoKind.name}
                                            checked={selectedAccoKind}
                                            disabled={
                                                (!selectedAccoKind && availableAccoKinds.findIndex((someAccoKind) => someAccoKind.accommodationkindId === accoKind.accommodationkindId) === -1) ||
                                                !isSelected
                                            }
                                            className="search-window__item-input"
                                        />
                                        <label className="accokind-name search-window__item-label" htmlFor={`accokinds-${count}`}>
                                            {accoKind.name}
                                        </label>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {options.showApplyClear && (
                        <div className="filter-action-buttons">
                            <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                            </a>
                            <a className="action-button__apply" role="button" onClick={this.applyResults}>
                                {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                            </a>
                        </div>
                    )}
                </ModalFooter>
            </Modal>
        );
    };
}
/* jscpd:ignore-end */
function mapStateToProps(state: State): AccommodationKindFacetStoreProps {
    const availabilityResult: AvailabilityResult | undefined = state.availabilityState.availabilityResult;
    const availabilityIsFetching: boolean = state.availabilityState.fetching || false;

    return {
        dynamicFilter: state.dynamicFilter,
        availableAccoKindIds: availabilityResult && availabilityResult.response.accommodationkinds ? availabilityResult.response.accommodationkinds : [],
        availabilityIsFetching,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction>): AccommodationKindFacetDispatchProps {
    return { dispatchAction: dispatch };
}

async function getAllBookableAccoKinds(props: AccommodationKindFacetProps, accoKindIds?: number[]): Promise<AccoKind[]> {
    const { context, dynamicFilter } = props;
    if (!accoKindIds?.length) {
        return [];
    }

    const env = await getMxtsEnv(context, context.currentLocale.code);
    const allBookableAccoKinds = await context.mxtsApi.accommodationkinds(env, { accoKindId: accoKindIds, sort: "priority", size: 999 });
    return allBookableAccoKinds.content;
}

// eslint-disable-next-line max-len
const AccommodationKindFacet = connect<AccommodationKindFacetStoreProps, AccommodationKindFacetDispatchProps>(mapStateToProps, mapDispatchToProps)(AccommodationKindFacetWidget);

export const DynamicAccommodationKindFacet = wrapProps<DynamicWidgetBaseProps<WidgetOptions>>(AccommodationKindFacet);

export async function warmupCache(props: AccommodationKindFacetProps): Promise<void> {
    await getAllBookableAccoKinds(props);
}
